import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: any,
  DateTime: any,
};

export type AddRemoteEventInput = {
  remoteEventExtractionId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  alternativeName?: Maybe<Scalars['String']>,
  description: Scalars['String'],
  placeId?: Maybe<Scalars['String']>,
  online: Scalars['Boolean'],
  image?: Maybe<Scalars['String']>,
  fromDate: Scalars['Date'],
  toDate: Scalars['Date'],
  approximateDate?: Maybe<Scalars['String']>,
  url: Scalars['String'],
  speakers?: Maybe<Array<Maybe<AddRemoteSpeakerInput>>>,
  images?: Maybe<Array<Maybe<AddRemoteImageInput>>>,
  partners?: Maybe<Array<Maybe<AddRemotePartnerInput>>>,
  slug?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type AddRemoteEventPayload = {
   __typename?: 'AddRemoteEventPayload',
  event?: Maybe<Event>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type AddRemoteImageInput = {
  src: Scalars['String'],
  alt?: Maybe<Scalars['String']>,
  mainImage?: Maybe<Scalars['Boolean']>,
};

export type AddRemotePartnerInput = {
  name: Scalars['String'],
  partnershipType: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type AddRemoteSpeakerInput = {
  name: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  resume?: Maybe<Scalars['String']>,
  facebookLink?: Maybe<Scalars['String']>,
  linkedinLink?: Maybe<Scalars['String']>,
  twitterLink?: Maybe<Scalars['String']>,
  youtubeLink?: Maybe<Scalars['String']>,
};

export type ContactUsInput = {
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  message: Scalars['String'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ContactUsPayload = {
   __typename?: 'ContactUsPayload',
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  message: Scalars['String'],
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  result: Scalars['String'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type CreateNewEventRequestInput = {
  url: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  listingType?: Maybe<Scalars['String']>,
  partnersLink?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type CreateNewEventRequestPayload = {
   __typename?: 'CreateNewEventRequestPayload',
  newEventRequest?: Maybe<NewEventRequestType>,
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type CreatePaymentInput = {
  eventId: Scalars['ID'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type CreatePaymentPayload = {
   __typename?: 'CreatePaymentPayload',
  result: Scalars['String'],
  sessionId?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};



export type ErrorType = {
   __typename?: 'ErrorType',
  field: Scalars['String'],
  messages: Array<Scalars['String']>,
};

export type Event = Node & {
   __typename?: 'Event',
  name: Scalars['String'],
  alternativeName?: Maybe<Scalars['String']>,
  slug: Scalars['String'],
  fromDate: Scalars['Date'],
  toDate: Scalars['Date'],
  approximateDate?: Maybe<Scalars['String']>,
  place?: Maybe<Place>,
  online: Scalars['Boolean'],
  url?: Maybe<Scalars['String']>,
  promotionNote?: Maybe<Scalars['String']>,
  promotionToDate?: Maybe<Scalars['Date']>,
  featured: Scalars['Boolean'],
  canceled: Scalars['Boolean'],
  images?: Maybe<ImageConnection>,
  speakers?: Maybe<SpeakerConnection>,
  youtubePlaylistIds: Array<Scalars['String']>,
  id: Scalars['ID'],
  date: Scalars['String'],
  daysToStart: Scalars['Int'],
  description: Scalars['String'],
  rawDescription: Scalars['String'],
  image?: Maybe<Image>,
  isOver: Scalars['Boolean'],
  promoted: Scalars['Boolean'],
  promotionPrice?: Maybe<Scalars['String']>,
  promotionPricePerDay?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  partners?: Maybe<PartnerConnection>,
  topics: Array<Scalars['String']>,
};


export type EventimagesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EventspeakersArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EventpartnersArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type EventConnection = {
   __typename?: 'EventConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<EventEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type EventEdge = {
   __typename?: 'EventEdge',
  node?: Maybe<Event>,
  cursor: Scalars['String'],
};

export type EventUpdates = {
   __typename?: 'EventUpdates',
  newRemoteImages?: Maybe<RemoteImageConnection>,
  newRemoteSpeakers?: Maybe<RemoteSpeakerConnection>,
  remoteEventExtractions?: Maybe<RemoteEventExtractionConnection>,
};


export type EventUpdatesnewRemoteImagesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EventUpdatesnewRemoteSpeakersArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EventUpdatesremoteEventExtractionsArgs = {
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type IgnoreRemoteImagesInput = {
  remoteImageIds: Array<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type IgnoreRemoteImagesPayload = {
   __typename?: 'IgnoreRemoteImagesPayload',
  remoteImages: Array<RemoteImage>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type IgnoreRemoteSpeakersInput = {
  remoteSpeakerIds: Array<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type IgnoreRemoteSpeakersPayload = {
   __typename?: 'IgnoreRemoteSpeakersPayload',
  remoteSpeakers: Array<RemoteSpeaker>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type Image = Node & {
   __typename?: 'Image',
  caption?: Maybe<Scalars['String']>,
  cropX?: Maybe<Scalars['Float']>,
  cropY?: Maybe<Scalars['Float']>,
  cropWidth?: Maybe<Scalars['Float']>,
  cropHeight?: Maybe<Scalars['Float']>,
  id: Scalars['ID'],
  image?: Maybe<Scalars['String']>,
  imageWidth?: Maybe<Scalars['Int']>,
  imageHeight?: Maybe<Scalars['Int']>,
  imageCard?: Maybe<Scalars['String']>,
  imageCardWidth?: Maybe<Scalars['Int']>,
  imageCardHeight?: Maybe<Scalars['Int']>,
  imagePreview?: Maybe<Scalars['String']>,
  imageCoverPreview?: Maybe<Scalars['String']>,
  imageCardPreview?: Maybe<Scalars['String']>,
  imageOriginal?: Maybe<Scalars['String']>,
};

export type ImageConnection = {
   __typename?: 'ImageConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<ImageEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type ImageEdge = {
   __typename?: 'ImageEdge',
  node?: Maybe<Image>,
  cursor: Scalars['String'],
};

export type ImportImagesImage = {
  remoteImageId?: Maybe<Scalars['ID']>,
  src: Scalars['String'],
  alt?: Maybe<Scalars['String']>,
  mainImage?: Maybe<Scalars['Boolean']>,
};

export type ImportImagesInput = {
  eventId?: Maybe<Scalars['ID']>,
  images: Array<ImportImagesImage>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportImagesPayload = {
   __typename?: 'ImportImagesPayload',
  images: Array<Image>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportPartnersInput = {
  eventId?: Maybe<Scalars['ID']>,
  partners: Array<ImportPartnersPartner>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportPartnersPartner = {
  remoteImageId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  partnershipType: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type ImportPartnersPayload = {
   __typename?: 'ImportPartnersPayload',
  partners: Array<Partner>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportSpeakersInput = {
  eventId?: Maybe<Scalars['ID']>,
  speakers: Array<ImportSpeakersSpeaker>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportSpeakersPayload = {
   __typename?: 'ImportSpeakersPayload',
  speakers: Array<Speaker>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportSpeakersSpeaker = {
  remoteSpeakerId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  resume?: Maybe<Scalars['String']>,
  facebookLink?: Maybe<Scalars['String']>,
  linkedinLink?: Maybe<Scalars['String']>,
  twitterLink?: Maybe<Scalars['String']>,
  youtubeLink?: Maybe<Scalars['String']>,
};

export type LoadRemoteEventInput = {
  eventId?: Maybe<Scalars['ID']>,
  remotePages?: Maybe<Array<LoadRemoteEventRemotePageInput>>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type LoadRemoteEventPayload = {
   __typename?: 'LoadRemoteEventPayload',
  remoteEventExtraction: RemoteEventExtraction,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type LoadRemoteEventRemotePageInput = {
  url: Scalars['String'],
  providedContent?: Maybe<Scalars['String']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  addRemoteEvent?: Maybe<AddRemoteEventPayload>,
  ignoreRemoteImages?: Maybe<IgnoreRemoteImagesPayload>,
  ignoreRemoteSpeakers?: Maybe<IgnoreRemoteSpeakersPayload>,
  importImages?: Maybe<ImportImagesPayload>,
  importPartners?: Maybe<ImportPartnersPayload>,
  importSpeakers?: Maybe<ImportSpeakersPayload>,
  loadRemoteEvent?: Maybe<LoadRemoteEventPayload>,
  pruneRemoteEventExtraction?: Maybe<PruneRemoteEventExtractionsPayload>,
  createNewEventRequest?: Maybe<CreateNewEventRequestPayload>,
  createPayment?: Maybe<CreatePaymentPayload>,
  saveSpeaker?: Maybe<SaveSpeakerPayload>,
  newsletterSubscribe?: Maybe<NewsletterSubscribePayload>,
  updateEvent?: Maybe<UpdateEventPayload>,
  updateImage?: Maybe<UpdateImagePayload>,
  contactUs?: Maybe<ContactUsPayload>,
};


export type MutationaddRemoteEventArgs = {
  input: AddRemoteEventInput
};


export type MutationignoreRemoteImagesArgs = {
  input: IgnoreRemoteImagesInput
};


export type MutationignoreRemoteSpeakersArgs = {
  input: IgnoreRemoteSpeakersInput
};


export type MutationimportImagesArgs = {
  input: ImportImagesInput
};


export type MutationimportPartnersArgs = {
  input: ImportPartnersInput
};


export type MutationimportSpeakersArgs = {
  input: ImportSpeakersInput
};


export type MutationloadRemoteEventArgs = {
  input: LoadRemoteEventInput
};


export type MutationpruneRemoteEventExtractionArgs = {
  input: PruneRemoteEventExtractionsInput
};


export type MutationcreateNewEventRequestArgs = {
  input: CreateNewEventRequestInput
};


export type MutationcreatePaymentArgs = {
  input: CreatePaymentInput
};


export type MutationsaveSpeakerArgs = {
  input: SaveSpeakerInput
};


export type MutationnewsletterSubscribeArgs = {
  input: NewsletterSubscribeInput
};


export type MutationupdateEventArgs = {
  input: UpdateEventInput
};


export type MutationupdateImageArgs = {
  input: UpdateImageInput
};


export type MutationcontactUsArgs = {
  input: ContactUsInput
};

export type NewEventRequestType = {
   __typename?: 'NewEventRequestType',
  url: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  listingType?: Maybe<Scalars['String']>,
  partnersLink?: Maybe<Scalars['String']>,
};

export type NewsletterSubscribeInput = {
  firstName: Scalars['String'],
  email: Scalars['String'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type NewsletterSubscribePayload = {
   __typename?: 'NewsletterSubscribePayload',
  result: Scalars['String'],
  message?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type Node = {
  id: Scalars['ID'],
};

export type PageInfo = {
   __typename?: 'PageInfo',
  hasNextPage: Scalars['Boolean'],
  hasPreviousPage: Scalars['Boolean'],
  startCursor?: Maybe<Scalars['String']>,
  endCursor?: Maybe<Scalars['String']>,
};

export type Partner = Node & {
   __typename?: 'Partner',
  name: Scalars['String'],
  image?: Maybe<Image>,
  id: Scalars['ID'],
};

export type PartnerConnection = {
   __typename?: 'PartnerConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<PartnerEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type PartnerEdge = {
   __typename?: 'PartnerEdge',
  node?: Maybe<Partner>,
  cursor: Scalars['String'],
  partnershipType?: Maybe<PartnershipType>,
};

export type PartnershipType = Node & {
   __typename?: 'PartnershipType',
  name: Scalars['String'],
  id: Scalars['ID'],
};

export type Place = Node & {
   __typename?: 'Place',
  name: Scalars['String'],
  country: Scalars['String'],
  locality?: Maybe<Scalars['String']>,
  formattedAddress: Scalars['String'],
  googlePlaceId: Scalars['String'],
  lat: Scalars['Float'],
  lng: Scalars['Float'],
  id: Scalars['ID'],
};

export type PruneRemoteEventExtractionsInput = {
  remoteEventExtractionIds?: Maybe<Array<Scalars['ID']>>,
  eventIds?: Maybe<Array<Scalars['ID']>>,
  pruneRemoteImages: Scalars['Boolean'],
  pruneRemoteSpeakers: Scalars['Boolean'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type PruneRemoteEventExtractionsPayload = {
   __typename?: 'PruneRemoteEventExtractionsPayload',
  remoteEventExtractions: Array<RemoteEventExtraction>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type Query = {
   __typename?: 'Query',
  node?: Maybe<Node>,
  eventUpdates: EventUpdates,
  remoteEventExtraction?: Maybe<RemoteEventExtraction>,
  remoteEventExtractions?: Maybe<RemoteEventExtractionConnection>,
  partnershipTypes: Array<PartnershipType>,
  events?: Maybe<EventConnection>,
  event?: Maybe<Event>,
  places?: Maybe<Array<Maybe<Place>>>,
  users2?: Maybe<Array<Maybe<User>>>,
  me?: Maybe<Scalars['String']>,
};


export type QuerynodeArgs = {
  id: Scalars['ID']
};


export type QueryeventUpdatesArgs = {
  slug: Scalars['String']
};


export type QueryremoteEventExtractionArgs = {
  id: Scalars['ID']
};


export type QueryremoteEventExtractionsArgs = {
  eventId?: Maybe<Scalars['ID']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryeventsArgs = {
  country?: Maybe<Scalars['String']>,
  featured?: Maybe<Scalars['Boolean']>,
  canceled?: Maybe<Scalars['Boolean']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryeventArgs = {
  slug?: Maybe<Scalars['String']>
};

export type RemoteEvent = Node & {
   __typename?: 'RemoteEvent',
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  fromDate?: Maybe<Scalars['Date']>,
  toDate?: Maybe<Scalars['Date']>,
  place?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
};

export type RemoteEventExtraction = Node & {
   __typename?: 'RemoteEventExtraction',
  event?: Maybe<Event>,
  status: Scalars['String'],
  message?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  remoteEvent?: Maybe<RemoteEvent>,
  id: Scalars['ID'],
  remotePages?: Maybe<RemotePageConnection>,
  remoteImages?: Maybe<RemoteImageConnection>,
  remoteSpeakers?: Maybe<RemoteSpeakerConnection>,
};


export type RemoteEventExtractionremotePagesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type RemoteEventExtractionremoteImagesArgs = {
  status?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type RemoteEventExtractionremoteSpeakersArgs = {
  status?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type RemoteEventExtractionConnection = {
   __typename?: 'RemoteEventExtractionConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<RemoteEventExtractionEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type RemoteEventExtractionEdge = {
   __typename?: 'RemoteEventExtractionEdge',
  node?: Maybe<RemoteEventExtraction>,
  cursor: Scalars['String'],
};

export type RemoteImage = Node & {
   __typename?: 'RemoteImage',
  src: Scalars['String'],
  position: Scalars['String'],
  category?: Maybe<Scalars['String']>,
  alt?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['Int']>,
  width?: Maybe<Scalars['Int']>,
  height?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  partnershipType: PartnershipType,
};

export type RemoteImageConnection = {
   __typename?: 'RemoteImageConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<RemoteImageEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type RemoteImageEdge = {
   __typename?: 'RemoteImageEdge',
  node?: Maybe<RemoteImage>,
  cursor: Scalars['String'],
};

export type RemotePage = Node & {
   __typename?: 'RemotePage',
  url: Scalars['String'],
  content?: Maybe<Scalars['String']>,
  contentProvided: Scalars['Boolean'],
  autodiscovered: Scalars['Boolean'],
  labels: Array<Scalars['String']>,
  id: Scalars['ID'],
};

export type RemotePageConnection = {
   __typename?: 'RemotePageConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<RemotePageEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type RemotePageEdge = {
   __typename?: 'RemotePageEdge',
  node?: Maybe<RemotePage>,
  cursor: Scalars['String'],
};

export type RemoteSpeaker = Node & {
   __typename?: 'RemoteSpeaker',
  name?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  detailUrl?: Maybe<Scalars['String']>,
  facebookLink?: Maybe<Scalars['String']>,
  linkedinLink?: Maybe<Scalars['String']>,
  twitterLink?: Maybe<Scalars['String']>,
  youtubeLink?: Maybe<Scalars['String']>,
  group?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
};

export type RemoteSpeakerConnection = {
   __typename?: 'RemoteSpeakerConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<RemoteSpeakerEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type RemoteSpeakerEdge = {
   __typename?: 'RemoteSpeakerEdge',
  node?: Maybe<RemoteSpeaker>,
  cursor: Scalars['String'],
};

export type SaveSpeakerInput = {
  name: Scalars['String'],
  bio?: Maybe<Scalars['String']>,
  resume?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['ID']>,
  linkedinLink?: Maybe<Scalars['String']>,
  facebookLink?: Maybe<Scalars['String']>,
  twitterLink?: Maybe<Scalars['String']>,
  youtubeLink?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type SaveSpeakerPayload = {
   __typename?: 'SaveSpeakerPayload',
  speaker?: Maybe<Speaker>,
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  error?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type Speaker = Node & {
   __typename?: 'Speaker',
  name: Scalars['String'],
  bio?: Maybe<Scalars['String']>,
  resume?: Maybe<Scalars['String']>,
  image?: Maybe<Image>,
  id: Scalars['ID'],
};

export type SpeakerConnection = {
   __typename?: 'SpeakerConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<SpeakerEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type SpeakerEdge = {
   __typename?: 'SpeakerEdge',
  node?: Maybe<Speaker>,
  cursor: Scalars['String'],
};

export type UpdateEventInput = {
  name: Scalars['String'],
  alternativeName?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  fromDate: Scalars['Date'],
  toDate: Scalars['Date'],
  url?: Maybe<Scalars['String']>,
  approximateDate?: Maybe<Scalars['String']>,
  online?: Maybe<Scalars['Boolean']>,
  canceled?: Maybe<Scalars['Boolean']>,
  placeId?: Maybe<Scalars['String']>,
  rawDescription: Scalars['String'],
  id?: Maybe<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type UpdateEventPayload = {
   __typename?: 'UpdateEventPayload',
  event?: Maybe<Event>,
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  error?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type UpdateImageInput = {
  caption?: Maybe<Scalars['String']>,
  cropX?: Maybe<Scalars['Float']>,
  cropY?: Maybe<Scalars['Float']>,
  cropWidth?: Maybe<Scalars['Float']>,
  cropHeight?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type UpdateImagePayload = {
   __typename?: 'UpdateImagePayload',
  image?: Maybe<Image>,
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  error?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  password: Scalars['String'],
  lastLogin?: Maybe<Scalars['DateTime']>,
  isSuperuser: Scalars['Boolean'],
  username: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  isStaff: Scalars['Boolean'],
  isActive: Scalars['Boolean'],
  dateJoined: Scalars['DateTime'],
};

export type CreatePaymentMutationMutationVariables = {
  input: CreatePaymentInput
};


export type CreatePaymentMutationMutation = (
  { __typename?: 'Mutation' }
  & { createPayment: Maybe<(
    { __typename?: 'CreatePaymentPayload' }
    & Pick<CreatePaymentPayload, 'result' | 'sessionId'>
  )> }
);

export type PromoteEvent_eventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'daysToStart' | 'name' | 'fromDate' | 'promotionNote' | 'promotionPrice' | 'promotionPricePerDay' | 'promotionToDate'>
);

export type NewsletterSubscribeMutationMutationVariables = {
  input: NewsletterSubscribeInput
};


export type NewsletterSubscribeMutationMutation = (
  { __typename?: 'Mutation' }
  & { newsletterSubscribe: Maybe<(
    { __typename?: 'NewsletterSubscribePayload' }
    & Pick<NewsletterSubscribePayload, 'result' | 'message'>
  )> }
);

export type ImportRemoteEvent_remoteEventFragment = (
  { __typename?: 'RemoteEvent' }
  & Pick<RemoteEvent, 'name' | 'description' | 'url' | 'place' | 'fromDate' | 'toDate'>
);

export type LoadRemoteEventMutationMutationVariables = {
  input: LoadRemoteEventInput
};


export type LoadRemoteEventMutationMutation = (
  { __typename?: 'Mutation' }
  & { loadRemoteEvent: Maybe<(
    { __typename?: 'LoadRemoteEventPayload' }
    & { remoteEventExtraction: (
      { __typename?: 'RemoteEventExtraction' }
      & Pick<RemoteEventExtraction, 'id'>
    ) }
  )> }
);

export type RemoteEventExtractionList_remoteEventExtractionFragment = (
  { __typename?: 'RemoteEventExtraction' }
  & Pick<RemoteEventExtraction, 'id' | 'status' | 'message' | 'createdAt' | 'updatedAt'>
  & { remotePages: Maybe<(
    { __typename?: 'RemotePageConnection' }
    & Pick<RemotePageConnection, 'totalCount'>
    & { edges: Array<Maybe<(
      { __typename?: 'RemotePageEdge' }
      & { node: Maybe<(
        { __typename?: 'RemotePage' }
        & Pick<RemotePage, 'url' | 'labels' | 'autodiscovered' | 'contentProvided'>
      )> }
    )>> }
  )>, event: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'name' | 'slug'>
  )>, remoteEvent: Maybe<(
    { __typename?: 'RemoteEvent' }
    & Pick<RemoteEvent, 'name' | 'place' | 'fromDate' | 'toDate'>
  )>, remoteImages: Maybe<(
    { __typename?: 'RemoteImageConnection' }
    & Pick<RemoteImageConnection, 'totalCount'>
  )>, remoteSpeakers: Maybe<(
    { __typename?: 'RemoteSpeakerConnection' }
    & Pick<RemoteSpeakerConnection, 'totalCount'>
  )> }
);

export type PruneRemoteEventExtractionMutationMutationVariables = {
  input: PruneRemoteEventExtractionsInput
};


export type PruneRemoteEventExtractionMutationMutation = (
  { __typename?: 'Mutation' }
  & { pruneRemoteEventExtraction: Maybe<(
    { __typename?: 'PruneRemoteEventExtractionsPayload' }
    & { remoteEventExtractions: Array<(
      { __typename?: 'RemoteEventExtraction' }
      & RemoteEventExtractionList_remoteEventExtractionFragment
    )> }
  )> }
);

export type RemoteEventExtractionsQueryQueryVariables = {
  eventId?: Maybe<Scalars['ID']>,
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type RemoteEventExtractionsQueryQuery = (
  { __typename?: 'Query' }
  & { remoteEventExtractions: Maybe<(
    { __typename?: 'RemoteEventExtractionConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ), edges: Array<Maybe<(
      { __typename?: 'RemoteEventExtractionEdge' }
      & { node: Maybe<(
        { __typename?: 'RemoteEventExtraction' }
        & RemoteEventExtractionList_remoteEventExtractionFragment
      )> }
    )>> }
  )> }
);

export type RemoteImage_partnershipTypeFragment = (
  { __typename?: 'PartnershipType' }
  & Pick<PartnershipType, 'id' | 'name'>
);

export type RemoteImage_remoteImageFragment = (
  { __typename?: 'RemoteImage' }
  & Pick<RemoteImage, 'id' | 'src' | 'category' | 'width' | 'height' | 'alt' | 'link' | 'size'>
  & { partnershipType: (
    { __typename?: 'PartnershipType' }
    & RemoteImage_partnershipTypeFragment
  ) }
);

export type RemoteSpeaker_remoteSpeakerFragment = (
  { __typename?: 'RemoteSpeaker' }
  & Pick<RemoteSpeaker, 'id' | 'name' | 'image' | 'group' | 'bio' | 'facebookLink' | 'linkedinLink' | 'twitterLink' | 'youtubeLink'>
);

export type UpdateEvent_eventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'alternativeName' | 'rawDescription' | 'summary' | 'fromDate' | 'toDate' | 'approximateDate' | 'url' | 'online' | 'canceled'>
  & { place: Maybe<(
    { __typename?: 'Place' }
    & Pick<Place, 'googlePlaceId'>
  )>, image: Maybe<(
    { __typename?: 'Image' }
    & UpdateEventMainImage_imageFragment
  )> }
);

export type UpdateEventMutationMutationVariables = {
  input: UpdateEventInput
};


export type UpdateEventMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent: Maybe<(
    { __typename?: 'UpdateEventPayload' }
    & { errors: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>>, event: Maybe<(
      { __typename?: 'Event' }
      & UpdateEvent_eventFragment
    )> }
  )> }
);

export type ImportPartnersMutationMutationVariables = {
  input: ImportPartnersInput
};


export type ImportPartnersMutationMutation = (
  { __typename?: 'Mutation' }
  & { importPartners: Maybe<(
    { __typename?: 'ImportPartnersPayload' }
    & { partners: Array<(
      { __typename?: 'Partner' }
      & Pick<Partner, 'id'>
    )> }
  )> }
);

export type ImportImagesMutationMutationVariables = {
  input: ImportImagesInput
};


export type ImportImagesMutationMutation = (
  { __typename?: 'Mutation' }
  & { importImages: Maybe<(
    { __typename?: 'ImportImagesPayload' }
    & { images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'id'>
    )> }
  )> }
);

export type IgnoreRemoteImagesMutationMutationVariables = {
  input: IgnoreRemoteImagesInput
};


export type IgnoreRemoteImagesMutationMutation = (
  { __typename?: 'Mutation' }
  & { ignoreRemoteImages: Maybe<(
    { __typename?: 'IgnoreRemoteImagesPayload' }
    & { remoteImages: Array<(
      { __typename?: 'RemoteImage' }
      & Pick<RemoteImage, 'id'>
    )> }
  )> }
);

export type UpdateEventImages_eventUpdatesFragment = (
  { __typename?: 'EventUpdates' }
  & { newRemoteImages: Maybe<(
    { __typename?: 'RemoteImageConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'RemoteImageEdge' }
      & { node: Maybe<(
        { __typename?: 'RemoteImage' }
        & RemoteImage_remoteImageFragment
      )> }
    )>> }
  )> }
);

export type UpdateEventImages_partnershipTypeFragment = (
  { __typename?: 'PartnershipType' }
  & Pick<PartnershipType, 'id' | 'name'>
);

export type UpdateEventImages_eventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'slug'>
);

export type UpdateEventLayout_eventUpdatesFragment = (
  { __typename?: 'EventUpdates' }
  & { newRemoteSpeakers: Maybe<(
    { __typename?: 'RemoteSpeakerConnection' }
    & Pick<RemoteSpeakerConnection, 'totalCount'>
  )>, newRemoteImages: Maybe<(
    { __typename?: 'RemoteImageConnection' }
    & Pick<RemoteImageConnection, 'totalCount'>
  )>, pendingRemoteEventExtractions: Maybe<(
    { __typename?: 'RemoteEventExtractionConnection' }
    & Pick<RemoteEventExtractionConnection, 'totalCount'>
  )>, remoteEventExtractions: Maybe<(
    { __typename?: 'RemoteEventExtractionConnection' }
    & Pick<RemoteEventExtractionConnection, 'totalCount'>
  )> }
);

export type UpdateImageMutationMutationVariables = {
  input: UpdateImageInput
};


export type UpdateImageMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateImage: Maybe<(
    { __typename?: 'UpdateImagePayload' }
    & { errors: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateEventMainImage_imageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'id' | 'caption' | 'image' | 'imageOriginal' | 'cropX' | 'cropY' | 'cropWidth' | 'cropHeight'>
);

export type ImportSpeakersMutationMutationVariables = {
  input: ImportSpeakersInput
};


export type ImportSpeakersMutationMutation = (
  { __typename?: 'Mutation' }
  & { importSpeakers: Maybe<(
    { __typename?: 'ImportSpeakersPayload' }
    & { speakers: Array<(
      { __typename?: 'Speaker' }
      & Pick<Speaker, 'id'>
    )> }
  )> }
);

export type IgnoreRemoteSpeakersMutationMutationVariables = {
  input: IgnoreRemoteSpeakersInput
};


export type IgnoreRemoteSpeakersMutationMutation = (
  { __typename?: 'Mutation' }
  & { ignoreRemoteSpeakers: Maybe<(
    { __typename?: 'IgnoreRemoteSpeakersPayload' }
    & { remoteSpeakers: Array<(
      { __typename?: 'RemoteSpeaker' }
      & Pick<RemoteSpeaker, 'id'>
    )> }
  )> }
);

export type UpdateEventSpeakers_eventUpdatesFragment = (
  { __typename?: 'EventUpdates' }
  & { newRemoteSpeakers: Maybe<(
    { __typename?: 'RemoteSpeakerConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'RemoteSpeakerEdge' }
      & { node: Maybe<(
        { __typename?: 'RemoteSpeaker' }
        & RemoteSpeaker_remoteSpeakerFragment
      )> }
    )>> }
  )> }
);

export type UpdateEventSpeakers_eventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'slug'>
);

export type UpdateEventExtractionsQueryQueryVariables = {
  slug: Scalars['String']
};


export type UpdateEventExtractionsQueryQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )>, eventUpdates: (
    { __typename?: 'EventUpdates' }
    & { recentRemoteEventExtractions: Maybe<(
      { __typename?: 'RemoteEventExtractionConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'RemoteEventExtractionEdge' }
        & { node: Maybe<(
          { __typename?: 'RemoteEventExtraction' }
          & { remotePages: Maybe<(
            { __typename?: 'RemotePageConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'RemotePageEdge' }
              & { node: Maybe<(
                { __typename?: 'RemotePage' }
                & Pick<RemotePage, 'url' | 'contentProvided' | 'autodiscovered' | 'labels'>
              )> }
            )>> }
          )> }
        )> }
      )>> }
    )> }
    & UpdateEventLayout_eventUpdatesFragment
  ) }
);

export type UpdateEventImagesQueryQueryVariables = {
  slug: Scalars['String']
};


export type UpdateEventImagesQueryQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & UpdateEventImages_eventFragment
  )>, eventUpdates: (
    { __typename?: 'EventUpdates' }
    & UpdateEventLayout_eventUpdatesFragment
    & UpdateEventImages_eventUpdatesFragment
  ), partnershipTypes: Array<(
    { __typename?: 'PartnershipType' }
    & UpdateEventImages_partnershipTypeFragment
  )> }
);

export type UpdateEventGeneralQueryQueryVariables = {
  slug: Scalars['String']
};


export type UpdateEventGeneralQueryQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & UpdateEvent_eventFragment
  )>, eventUpdates: (
    { __typename?: 'EventUpdates' }
    & UpdateEventLayout_eventUpdatesFragment
  ) }
);

export type UpdateEventMainImageQueryQueryVariables = {
  slug: Scalars['String']
};


export type UpdateEventMainImageQueryQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & { image: Maybe<(
      { __typename?: 'Image' }
      & UpdateEventMainImage_imageFragment
    )> }
    & UpdateEvent_eventFragment
  )>, eventUpdates: (
    { __typename?: 'EventUpdates' }
    & UpdateEventLayout_eventUpdatesFragment
  ) }
);

export type UpdateEventSpeakersQueryQueryVariables = {
  slug: Scalars['String']
};


export type UpdateEventSpeakersQueryQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & UpdateEvent_eventFragment
    & UpdateEventSpeakers_eventFragment
  )>, eventUpdates: (
    { __typename?: 'EventUpdates' }
    & UpdateEventLayout_eventUpdatesFragment
    & UpdateEventSpeakers_eventUpdatesFragment
  ) }
);

export type PromoteEventQueryQueryVariables = {
  id: Scalars['ID']
};


export type PromoteEventQueryQuery = (
  { __typename?: 'Query' }
  & { node: Maybe<(
    { __typename?: 'RemoteImage' }
    & Pick<RemoteImage, 'id'>
  ) | (
    { __typename?: 'PartnershipType' }
    & Pick<PartnershipType, 'id'>
  ) | (
    { __typename?: 'RemoteSpeaker' }
    & Pick<RemoteSpeaker, 'id'>
  ) | (
    { __typename?: 'RemoteEventExtraction' }
    & Pick<RemoteEventExtraction, 'id'>
  ) | (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & PromoteEvent_eventFragment
  ) | (
    { __typename?: 'Place' }
    & Pick<Place, 'id'>
  ) | (
    { __typename?: 'Image' }
    & Pick<Image, 'id'>
  ) | (
    { __typename?: 'Speaker' }
    & Pick<Speaker, 'id'>
  ) | (
    { __typename?: 'Partner' }
    & Pick<Partner, 'id'>
  ) | (
    { __typename?: 'RemoteEvent' }
    & Pick<RemoteEvent, 'id'>
  ) | (
    { __typename?: 'RemotePage' }
    & Pick<RemotePage, 'id'>
  )> }
);

export type RemoteEventExtractionQueryQueryVariables = {
  id: Scalars['ID']
};


export type RemoteEventExtractionQueryQuery = (
  { __typename?: 'Query' }
  & { remoteEventExtraction: Maybe<(
    { __typename?: 'RemoteEventExtraction' }
    & { remoteEvent: Maybe<(
      { __typename?: 'RemoteEvent' }
      & ImportRemoteEvent_remoteEventFragment
    )> }
  )> }
);

export const PromoteEvent_eventFragmentDoc = gql`
    fragment PromoteEvent_event on Event {
  id
  daysToStart
  name
  fromDate
  promotionNote
  promotionPrice
  promotionPricePerDay
  promotionToDate
}
    `;
export const ImportRemoteEvent_remoteEventFragmentDoc = gql`
    fragment ImportRemoteEvent_remoteEvent on RemoteEvent {
  name
  description
  url
  place
  fromDate
  toDate
}
    `;
export const RemoteEventExtractionList_remoteEventExtractionFragmentDoc = gql`
    fragment RemoteEventExtractionList_remoteEventExtraction on RemoteEventExtraction {
  id
  status
  message
  createdAt
  updatedAt
  remotePages {
    edges {
      node {
        url
        labels
        autodiscovered
        contentProvided
      }
    }
    totalCount
  }
  event {
    name
    slug
  }
  remoteEvent {
    name
    place
    fromDate
    toDate
  }
  remoteImages(status: "PENDING") {
    totalCount
  }
  remoteSpeakers(status: "PENDING") {
    totalCount
  }
}
    `;
export const UpdateEventMainImage_imageFragmentDoc = gql`
    fragment UpdateEventMainImage_image on Image {
  id
  caption
  image
  imageOriginal
  cropX
  cropY
  cropWidth
  cropHeight
}
    `;
export const UpdateEvent_eventFragmentDoc = gql`
    fragment UpdateEvent_event on Event {
  id
  name
  alternativeName
  rawDescription
  summary
  fromDate
  toDate
  approximateDate
  url
  online
  canceled
  place {
    googlePlaceId
  }
  image {
    ...UpdateEventMainImage_image
  }
}
    ${UpdateEventMainImage_imageFragmentDoc}`;
export const RemoteImage_partnershipTypeFragmentDoc = gql`
    fragment RemoteImage_partnershipType on PartnershipType {
  id
  name
}
    `;
export const RemoteImage_remoteImageFragmentDoc = gql`
    fragment RemoteImage_remoteImage on RemoteImage {
  id
  src
  category
  width
  height
  alt
  link
  size
  partnershipType {
    ...RemoteImage_partnershipType
  }
}
    ${RemoteImage_partnershipTypeFragmentDoc}`;
export const UpdateEventImages_eventUpdatesFragmentDoc = gql`
    fragment UpdateEventImages_eventUpdates on EventUpdates {
  newRemoteImages {
    edges {
      node {
        ...RemoteImage_remoteImage
      }
    }
  }
}
    ${RemoteImage_remoteImageFragmentDoc}`;
export const UpdateEventImages_partnershipTypeFragmentDoc = gql`
    fragment UpdateEventImages_partnershipType on PartnershipType {
  id
  name
}
    `;
export const UpdateEventImages_eventFragmentDoc = gql`
    fragment UpdateEventImages_event on Event {
  id
  slug
}
    `;
export const UpdateEventLayout_eventUpdatesFragmentDoc = gql`
    fragment UpdateEventLayout_eventUpdates on EventUpdates {
  newRemoteSpeakers {
    totalCount
  }
  newRemoteImages {
    totalCount
  }
  pendingRemoteEventExtractions: remoteEventExtractions(status: ["PENDING"]) {
    totalCount
  }
  remoteEventExtractions: remoteEventExtractions {
    totalCount
  }
}
    `;
export const RemoteSpeaker_remoteSpeakerFragmentDoc = gql`
    fragment RemoteSpeaker_remoteSpeaker on RemoteSpeaker {
  id
  name
  image
  group
  bio
  facebookLink
  linkedinLink
  twitterLink
  youtubeLink
}
    `;
export const UpdateEventSpeakers_eventUpdatesFragmentDoc = gql`
    fragment UpdateEventSpeakers_eventUpdates on EventUpdates {
  newRemoteSpeakers {
    edges {
      node {
        ...RemoteSpeaker_remoteSpeaker
      }
    }
  }
}
    ${RemoteSpeaker_remoteSpeakerFragmentDoc}`;
export const UpdateEventSpeakers_eventFragmentDoc = gql`
    fragment UpdateEventSpeakers_event on Event {
  id
  slug
}
    `;
export const CreatePaymentMutationDocument = gql`
    mutation CreatePaymentMutation($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    result
    sessionId
  }
}
    `;
export type CreatePaymentMutationMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentMutationMutation, CreatePaymentMutationMutationVariables>;
export type CreatePaymentMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreatePaymentMutationMutation, CreatePaymentMutationMutationVariables>, 'mutation'>;

    export const CreatePaymentMutationComponent = (props: CreatePaymentMutationComponentProps) => (
      <ApolloReactComponents.Mutation<CreatePaymentMutationMutation, CreatePaymentMutationMutationVariables> mutation={CreatePaymentMutationDocument} {...props} />
    );
    
export type CreatePaymentMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreatePaymentMutationMutation, CreatePaymentMutationMutationVariables> & TChildProps;
export function withCreatePaymentMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreatePaymentMutationMutation,
  CreatePaymentMutationMutationVariables,
  CreatePaymentMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreatePaymentMutationMutation, CreatePaymentMutationMutationVariables, CreatePaymentMutationProps<TChildProps>>(CreatePaymentMutationDocument, {
      alias: 'createPaymentMutation',
      ...operationOptions
    });
};
export type CreatePaymentMutationMutationResult = ApolloReactCommon.MutationResult<CreatePaymentMutationMutation>;
export type CreatePaymentMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentMutationMutation, CreatePaymentMutationMutationVariables>;
export const NewsletterSubscribeMutationDocument = gql`
    mutation NewsletterSubscribeMutation($input: NewsletterSubscribeInput!) {
  newsletterSubscribe(input: $input) {
    result
    message
  }
}
    `;
export type NewsletterSubscribeMutationMutationFn = ApolloReactCommon.MutationFunction<NewsletterSubscribeMutationMutation, NewsletterSubscribeMutationMutationVariables>;
export type NewsletterSubscribeMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<NewsletterSubscribeMutationMutation, NewsletterSubscribeMutationMutationVariables>, 'mutation'>;

    export const NewsletterSubscribeMutationComponent = (props: NewsletterSubscribeMutationComponentProps) => (
      <ApolloReactComponents.Mutation<NewsletterSubscribeMutationMutation, NewsletterSubscribeMutationMutationVariables> mutation={NewsletterSubscribeMutationDocument} {...props} />
    );
    
export type NewsletterSubscribeMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<NewsletterSubscribeMutationMutation, NewsletterSubscribeMutationMutationVariables> & TChildProps;
export function withNewsletterSubscribeMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewsletterSubscribeMutationMutation,
  NewsletterSubscribeMutationMutationVariables,
  NewsletterSubscribeMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, NewsletterSubscribeMutationMutation, NewsletterSubscribeMutationMutationVariables, NewsletterSubscribeMutationProps<TChildProps>>(NewsletterSubscribeMutationDocument, {
      alias: 'newsletterSubscribeMutation',
      ...operationOptions
    });
};
export type NewsletterSubscribeMutationMutationResult = ApolloReactCommon.MutationResult<NewsletterSubscribeMutationMutation>;
export type NewsletterSubscribeMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<NewsletterSubscribeMutationMutation, NewsletterSubscribeMutationMutationVariables>;
export const LoadRemoteEventMutationDocument = gql`
    mutation LoadRemoteEventMutation($input: LoadRemoteEventInput!) {
  loadRemoteEvent(input: $input) {
    remoteEventExtraction {
      id
    }
  }
}
    `;
export type LoadRemoteEventMutationMutationFn = ApolloReactCommon.MutationFunction<LoadRemoteEventMutationMutation, LoadRemoteEventMutationMutationVariables>;
export type LoadRemoteEventMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoadRemoteEventMutationMutation, LoadRemoteEventMutationMutationVariables>, 'mutation'>;

    export const LoadRemoteEventMutationComponent = (props: LoadRemoteEventMutationComponentProps) => (
      <ApolloReactComponents.Mutation<LoadRemoteEventMutationMutation, LoadRemoteEventMutationMutationVariables> mutation={LoadRemoteEventMutationDocument} {...props} />
    );
    
export type LoadRemoteEventMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoadRemoteEventMutationMutation, LoadRemoteEventMutationMutationVariables> & TChildProps;
export function withLoadRemoteEventMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoadRemoteEventMutationMutation,
  LoadRemoteEventMutationMutationVariables,
  LoadRemoteEventMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoadRemoteEventMutationMutation, LoadRemoteEventMutationMutationVariables, LoadRemoteEventMutationProps<TChildProps>>(LoadRemoteEventMutationDocument, {
      alias: 'loadRemoteEventMutation',
      ...operationOptions
    });
};
export type LoadRemoteEventMutationMutationResult = ApolloReactCommon.MutationResult<LoadRemoteEventMutationMutation>;
export type LoadRemoteEventMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<LoadRemoteEventMutationMutation, LoadRemoteEventMutationMutationVariables>;
export const PruneRemoteEventExtractionMutationDocument = gql`
    mutation PruneRemoteEventExtractionMutation($input: PruneRemoteEventExtractionsInput!) {
  pruneRemoteEventExtraction(input: $input) {
    remoteEventExtractions {
      ...RemoteEventExtractionList_remoteEventExtraction
    }
  }
}
    ${RemoteEventExtractionList_remoteEventExtractionFragmentDoc}`;
export type PruneRemoteEventExtractionMutationMutationFn = ApolloReactCommon.MutationFunction<PruneRemoteEventExtractionMutationMutation, PruneRemoteEventExtractionMutationMutationVariables>;
export type PruneRemoteEventExtractionMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PruneRemoteEventExtractionMutationMutation, PruneRemoteEventExtractionMutationMutationVariables>, 'mutation'>;

    export const PruneRemoteEventExtractionMutationComponent = (props: PruneRemoteEventExtractionMutationComponentProps) => (
      <ApolloReactComponents.Mutation<PruneRemoteEventExtractionMutationMutation, PruneRemoteEventExtractionMutationMutationVariables> mutation={PruneRemoteEventExtractionMutationDocument} {...props} />
    );
    
export type PruneRemoteEventExtractionMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PruneRemoteEventExtractionMutationMutation, PruneRemoteEventExtractionMutationMutationVariables> & TChildProps;
export function withPruneRemoteEventExtractionMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PruneRemoteEventExtractionMutationMutation,
  PruneRemoteEventExtractionMutationMutationVariables,
  PruneRemoteEventExtractionMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PruneRemoteEventExtractionMutationMutation, PruneRemoteEventExtractionMutationMutationVariables, PruneRemoteEventExtractionMutationProps<TChildProps>>(PruneRemoteEventExtractionMutationDocument, {
      alias: 'pruneRemoteEventExtractionMutation',
      ...operationOptions
    });
};
export type PruneRemoteEventExtractionMutationMutationResult = ApolloReactCommon.MutationResult<PruneRemoteEventExtractionMutationMutation>;
export type PruneRemoteEventExtractionMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<PruneRemoteEventExtractionMutationMutation, PruneRemoteEventExtractionMutationMutationVariables>;
export const RemoteEventExtractionsQueryDocument = gql`
    query RemoteEventExtractionsQuery($eventId: ID, $first: Int!, $after: String) {
  remoteEventExtractions(eventId: $eventId, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...RemoteEventExtractionList_remoteEventExtraction
      }
    }
  }
}
    ${RemoteEventExtractionList_remoteEventExtractionFragmentDoc}`;
export type RemoteEventExtractionsQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RemoteEventExtractionsQueryQuery, RemoteEventExtractionsQueryQueryVariables>, 'query'> & ({ variables: RemoteEventExtractionsQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RemoteEventExtractionsQueryComponent = (props: RemoteEventExtractionsQueryComponentProps) => (
      <ApolloReactComponents.Query<RemoteEventExtractionsQueryQuery, RemoteEventExtractionsQueryQueryVariables> query={RemoteEventExtractionsQueryDocument} {...props} />
    );
    
export type RemoteEventExtractionsQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<RemoteEventExtractionsQueryQuery, RemoteEventExtractionsQueryQueryVariables> & TChildProps;
export function withRemoteEventExtractionsQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoteEventExtractionsQueryQuery,
  RemoteEventExtractionsQueryQueryVariables,
  RemoteEventExtractionsQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, RemoteEventExtractionsQueryQuery, RemoteEventExtractionsQueryQueryVariables, RemoteEventExtractionsQueryProps<TChildProps>>(RemoteEventExtractionsQueryDocument, {
      alias: 'remoteEventExtractionsQuery',
      ...operationOptions
    });
};
export type RemoteEventExtractionsQueryQueryResult = ApolloReactCommon.QueryResult<RemoteEventExtractionsQueryQuery, RemoteEventExtractionsQueryQueryVariables>;
export const UpdateEventMutationDocument = gql`
    mutation UpdateEventMutation($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    errors {
      field
      messages
    }
    event {
      ...UpdateEvent_event
    }
  }
}
    ${UpdateEvent_eventFragmentDoc}`;
export type UpdateEventMutationMutationFn = ApolloReactCommon.MutationFunction<UpdateEventMutationMutation, UpdateEventMutationMutationVariables>;
export type UpdateEventMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEventMutationMutation, UpdateEventMutationMutationVariables>, 'mutation'>;

    export const UpdateEventMutationComponent = (props: UpdateEventMutationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEventMutationMutation, UpdateEventMutationMutationVariables> mutation={UpdateEventMutationDocument} {...props} />
    );
    
export type UpdateEventMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateEventMutationMutation, UpdateEventMutationMutationVariables> & TChildProps;
export function withUpdateEventMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEventMutationMutation,
  UpdateEventMutationMutationVariables,
  UpdateEventMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEventMutationMutation, UpdateEventMutationMutationVariables, UpdateEventMutationProps<TChildProps>>(UpdateEventMutationDocument, {
      alias: 'updateEventMutation',
      ...operationOptions
    });
};
export type UpdateEventMutationMutationResult = ApolloReactCommon.MutationResult<UpdateEventMutationMutation>;
export type UpdateEventMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventMutationMutation, UpdateEventMutationMutationVariables>;
export const ImportPartnersMutationDocument = gql`
    mutation ImportPartnersMutation($input: ImportPartnersInput!) {
  importPartners(input: $input) {
    partners {
      id
    }
  }
}
    `;
export type ImportPartnersMutationMutationFn = ApolloReactCommon.MutationFunction<ImportPartnersMutationMutation, ImportPartnersMutationMutationVariables>;
export type ImportPartnersMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportPartnersMutationMutation, ImportPartnersMutationMutationVariables>, 'mutation'>;

    export const ImportPartnersMutationComponent = (props: ImportPartnersMutationComponentProps) => (
      <ApolloReactComponents.Mutation<ImportPartnersMutationMutation, ImportPartnersMutationMutationVariables> mutation={ImportPartnersMutationDocument} {...props} />
    );
    
export type ImportPartnersMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ImportPartnersMutationMutation, ImportPartnersMutationMutationVariables> & TChildProps;
export function withImportPartnersMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportPartnersMutationMutation,
  ImportPartnersMutationMutationVariables,
  ImportPartnersMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ImportPartnersMutationMutation, ImportPartnersMutationMutationVariables, ImportPartnersMutationProps<TChildProps>>(ImportPartnersMutationDocument, {
      alias: 'importPartnersMutation',
      ...operationOptions
    });
};
export type ImportPartnersMutationMutationResult = ApolloReactCommon.MutationResult<ImportPartnersMutationMutation>;
export type ImportPartnersMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportPartnersMutationMutation, ImportPartnersMutationMutationVariables>;
export const ImportImagesMutationDocument = gql`
    mutation ImportImagesMutation($input: ImportImagesInput!) {
  importImages(input: $input) {
    images {
      id
    }
  }
}
    `;
export type ImportImagesMutationMutationFn = ApolloReactCommon.MutationFunction<ImportImagesMutationMutation, ImportImagesMutationMutationVariables>;
export type ImportImagesMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportImagesMutationMutation, ImportImagesMutationMutationVariables>, 'mutation'>;

    export const ImportImagesMutationComponent = (props: ImportImagesMutationComponentProps) => (
      <ApolloReactComponents.Mutation<ImportImagesMutationMutation, ImportImagesMutationMutationVariables> mutation={ImportImagesMutationDocument} {...props} />
    );
    
export type ImportImagesMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ImportImagesMutationMutation, ImportImagesMutationMutationVariables> & TChildProps;
export function withImportImagesMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportImagesMutationMutation,
  ImportImagesMutationMutationVariables,
  ImportImagesMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ImportImagesMutationMutation, ImportImagesMutationMutationVariables, ImportImagesMutationProps<TChildProps>>(ImportImagesMutationDocument, {
      alias: 'importImagesMutation',
      ...operationOptions
    });
};
export type ImportImagesMutationMutationResult = ApolloReactCommon.MutationResult<ImportImagesMutationMutation>;
export type ImportImagesMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportImagesMutationMutation, ImportImagesMutationMutationVariables>;
export const IgnoreRemoteImagesMutationDocument = gql`
    mutation IgnoreRemoteImagesMutation($input: IgnoreRemoteImagesInput!) {
  ignoreRemoteImages(input: $input) {
    remoteImages {
      id
    }
  }
}
    `;
export type IgnoreRemoteImagesMutationMutationFn = ApolloReactCommon.MutationFunction<IgnoreRemoteImagesMutationMutation, IgnoreRemoteImagesMutationMutationVariables>;
export type IgnoreRemoteImagesMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IgnoreRemoteImagesMutationMutation, IgnoreRemoteImagesMutationMutationVariables>, 'mutation'>;

    export const IgnoreRemoteImagesMutationComponent = (props: IgnoreRemoteImagesMutationComponentProps) => (
      <ApolloReactComponents.Mutation<IgnoreRemoteImagesMutationMutation, IgnoreRemoteImagesMutationMutationVariables> mutation={IgnoreRemoteImagesMutationDocument} {...props} />
    );
    
export type IgnoreRemoteImagesMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<IgnoreRemoteImagesMutationMutation, IgnoreRemoteImagesMutationMutationVariables> & TChildProps;
export function withIgnoreRemoteImagesMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IgnoreRemoteImagesMutationMutation,
  IgnoreRemoteImagesMutationMutationVariables,
  IgnoreRemoteImagesMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, IgnoreRemoteImagesMutationMutation, IgnoreRemoteImagesMutationMutationVariables, IgnoreRemoteImagesMutationProps<TChildProps>>(IgnoreRemoteImagesMutationDocument, {
      alias: 'ignoreRemoteImagesMutation',
      ...operationOptions
    });
};
export type IgnoreRemoteImagesMutationMutationResult = ApolloReactCommon.MutationResult<IgnoreRemoteImagesMutationMutation>;
export type IgnoreRemoteImagesMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<IgnoreRemoteImagesMutationMutation, IgnoreRemoteImagesMutationMutationVariables>;
export const UpdateImageMutationDocument = gql`
    mutation UpdateImageMutation($input: UpdateImageInput!) {
  updateImage(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateImageMutationMutationFn = ApolloReactCommon.MutationFunction<UpdateImageMutationMutation, UpdateImageMutationMutationVariables>;
export type UpdateImageMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateImageMutationMutation, UpdateImageMutationMutationVariables>, 'mutation'>;

    export const UpdateImageMutationComponent = (props: UpdateImageMutationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateImageMutationMutation, UpdateImageMutationMutationVariables> mutation={UpdateImageMutationDocument} {...props} />
    );
    
export type UpdateImageMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateImageMutationMutation, UpdateImageMutationMutationVariables> & TChildProps;
export function withUpdateImageMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateImageMutationMutation,
  UpdateImageMutationMutationVariables,
  UpdateImageMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateImageMutationMutation, UpdateImageMutationMutationVariables, UpdateImageMutationProps<TChildProps>>(UpdateImageMutationDocument, {
      alias: 'updateImageMutation',
      ...operationOptions
    });
};
export type UpdateImageMutationMutationResult = ApolloReactCommon.MutationResult<UpdateImageMutationMutation>;
export type UpdateImageMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateImageMutationMutation, UpdateImageMutationMutationVariables>;
export const ImportSpeakersMutationDocument = gql`
    mutation ImportSpeakersMutation($input: ImportSpeakersInput!) {
  importSpeakers(input: $input) {
    speakers {
      id
    }
  }
}
    `;
export type ImportSpeakersMutationMutationFn = ApolloReactCommon.MutationFunction<ImportSpeakersMutationMutation, ImportSpeakersMutationMutationVariables>;
export type ImportSpeakersMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportSpeakersMutationMutation, ImportSpeakersMutationMutationVariables>, 'mutation'>;

    export const ImportSpeakersMutationComponent = (props: ImportSpeakersMutationComponentProps) => (
      <ApolloReactComponents.Mutation<ImportSpeakersMutationMutation, ImportSpeakersMutationMutationVariables> mutation={ImportSpeakersMutationDocument} {...props} />
    );
    
export type ImportSpeakersMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ImportSpeakersMutationMutation, ImportSpeakersMutationMutationVariables> & TChildProps;
export function withImportSpeakersMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportSpeakersMutationMutation,
  ImportSpeakersMutationMutationVariables,
  ImportSpeakersMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ImportSpeakersMutationMutation, ImportSpeakersMutationMutationVariables, ImportSpeakersMutationProps<TChildProps>>(ImportSpeakersMutationDocument, {
      alias: 'importSpeakersMutation',
      ...operationOptions
    });
};
export type ImportSpeakersMutationMutationResult = ApolloReactCommon.MutationResult<ImportSpeakersMutationMutation>;
export type ImportSpeakersMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportSpeakersMutationMutation, ImportSpeakersMutationMutationVariables>;
export const IgnoreRemoteSpeakersMutationDocument = gql`
    mutation IgnoreRemoteSpeakersMutation($input: IgnoreRemoteSpeakersInput!) {
  ignoreRemoteSpeakers(input: $input) {
    remoteSpeakers {
      id
    }
  }
}
    `;
export type IgnoreRemoteSpeakersMutationMutationFn = ApolloReactCommon.MutationFunction<IgnoreRemoteSpeakersMutationMutation, IgnoreRemoteSpeakersMutationMutationVariables>;
export type IgnoreRemoteSpeakersMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IgnoreRemoteSpeakersMutationMutation, IgnoreRemoteSpeakersMutationMutationVariables>, 'mutation'>;

    export const IgnoreRemoteSpeakersMutationComponent = (props: IgnoreRemoteSpeakersMutationComponentProps) => (
      <ApolloReactComponents.Mutation<IgnoreRemoteSpeakersMutationMutation, IgnoreRemoteSpeakersMutationMutationVariables> mutation={IgnoreRemoteSpeakersMutationDocument} {...props} />
    );
    
export type IgnoreRemoteSpeakersMutationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<IgnoreRemoteSpeakersMutationMutation, IgnoreRemoteSpeakersMutationMutationVariables> & TChildProps;
export function withIgnoreRemoteSpeakersMutation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IgnoreRemoteSpeakersMutationMutation,
  IgnoreRemoteSpeakersMutationMutationVariables,
  IgnoreRemoteSpeakersMutationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, IgnoreRemoteSpeakersMutationMutation, IgnoreRemoteSpeakersMutationMutationVariables, IgnoreRemoteSpeakersMutationProps<TChildProps>>(IgnoreRemoteSpeakersMutationDocument, {
      alias: 'ignoreRemoteSpeakersMutation',
      ...operationOptions
    });
};
export type IgnoreRemoteSpeakersMutationMutationResult = ApolloReactCommon.MutationResult<IgnoreRemoteSpeakersMutationMutation>;
export type IgnoreRemoteSpeakersMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<IgnoreRemoteSpeakersMutationMutation, IgnoreRemoteSpeakersMutationMutationVariables>;
export const UpdateEventExtractionsQueryDocument = gql`
    query UpdateEventExtractionsQuery($slug: String!) {
  event(slug: $slug) {
    id
  }
  eventUpdates(slug: $slug) {
    ...UpdateEventLayout_eventUpdates
    recentRemoteEventExtractions: remoteEventExtractions(first: 1) {
      edges {
        node {
          remotePages {
            edges {
              node {
                url
                contentProvided
                autodiscovered
                labels
              }
            }
          }
        }
      }
    }
  }
}
    ${UpdateEventLayout_eventUpdatesFragmentDoc}`;
export type UpdateEventExtractionsQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpdateEventExtractionsQueryQuery, UpdateEventExtractionsQueryQueryVariables>, 'query'> & ({ variables: UpdateEventExtractionsQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UpdateEventExtractionsQueryComponent = (props: UpdateEventExtractionsQueryComponentProps) => (
      <ApolloReactComponents.Query<UpdateEventExtractionsQueryQuery, UpdateEventExtractionsQueryQueryVariables> query={UpdateEventExtractionsQueryDocument} {...props} />
    );
    
export type UpdateEventExtractionsQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<UpdateEventExtractionsQueryQuery, UpdateEventExtractionsQueryQueryVariables> & TChildProps;
export function withUpdateEventExtractionsQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEventExtractionsQueryQuery,
  UpdateEventExtractionsQueryQueryVariables,
  UpdateEventExtractionsQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UpdateEventExtractionsQueryQuery, UpdateEventExtractionsQueryQueryVariables, UpdateEventExtractionsQueryProps<TChildProps>>(UpdateEventExtractionsQueryDocument, {
      alias: 'updateEventExtractionsQuery',
      ...operationOptions
    });
};
export type UpdateEventExtractionsQueryQueryResult = ApolloReactCommon.QueryResult<UpdateEventExtractionsQueryQuery, UpdateEventExtractionsQueryQueryVariables>;
export const UpdateEventImagesQueryDocument = gql`
    query UpdateEventImagesQuery($slug: String!) {
  event(slug: $slug) {
    ...UpdateEventImages_event
  }
  eventUpdates(slug: $slug) {
    ...UpdateEventLayout_eventUpdates
    ...UpdateEventImages_eventUpdates
  }
  partnershipTypes {
    ...UpdateEventImages_partnershipType
  }
}
    ${UpdateEventImages_eventFragmentDoc}
${UpdateEventLayout_eventUpdatesFragmentDoc}
${UpdateEventImages_eventUpdatesFragmentDoc}
${UpdateEventImages_partnershipTypeFragmentDoc}`;
export type UpdateEventImagesQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpdateEventImagesQueryQuery, UpdateEventImagesQueryQueryVariables>, 'query'> & ({ variables: UpdateEventImagesQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UpdateEventImagesQueryComponent = (props: UpdateEventImagesQueryComponentProps) => (
      <ApolloReactComponents.Query<UpdateEventImagesQueryQuery, UpdateEventImagesQueryQueryVariables> query={UpdateEventImagesQueryDocument} {...props} />
    );
    
export type UpdateEventImagesQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<UpdateEventImagesQueryQuery, UpdateEventImagesQueryQueryVariables> & TChildProps;
export function withUpdateEventImagesQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEventImagesQueryQuery,
  UpdateEventImagesQueryQueryVariables,
  UpdateEventImagesQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UpdateEventImagesQueryQuery, UpdateEventImagesQueryQueryVariables, UpdateEventImagesQueryProps<TChildProps>>(UpdateEventImagesQueryDocument, {
      alias: 'updateEventImagesQuery',
      ...operationOptions
    });
};
export type UpdateEventImagesQueryQueryResult = ApolloReactCommon.QueryResult<UpdateEventImagesQueryQuery, UpdateEventImagesQueryQueryVariables>;
export const UpdateEventGeneralQueryDocument = gql`
    query UpdateEventGeneralQuery($slug: String!) {
  event(slug: $slug) {
    ...UpdateEvent_event
  }
  eventUpdates(slug: $slug) {
    ...UpdateEventLayout_eventUpdates
  }
}
    ${UpdateEvent_eventFragmentDoc}
${UpdateEventLayout_eventUpdatesFragmentDoc}`;
export type UpdateEventGeneralQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpdateEventGeneralQueryQuery, UpdateEventGeneralQueryQueryVariables>, 'query'> & ({ variables: UpdateEventGeneralQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UpdateEventGeneralQueryComponent = (props: UpdateEventGeneralQueryComponentProps) => (
      <ApolloReactComponents.Query<UpdateEventGeneralQueryQuery, UpdateEventGeneralQueryQueryVariables> query={UpdateEventGeneralQueryDocument} {...props} />
    );
    
export type UpdateEventGeneralQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<UpdateEventGeneralQueryQuery, UpdateEventGeneralQueryQueryVariables> & TChildProps;
export function withUpdateEventGeneralQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEventGeneralQueryQuery,
  UpdateEventGeneralQueryQueryVariables,
  UpdateEventGeneralQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UpdateEventGeneralQueryQuery, UpdateEventGeneralQueryQueryVariables, UpdateEventGeneralQueryProps<TChildProps>>(UpdateEventGeneralQueryDocument, {
      alias: 'updateEventGeneralQuery',
      ...operationOptions
    });
};
export type UpdateEventGeneralQueryQueryResult = ApolloReactCommon.QueryResult<UpdateEventGeneralQueryQuery, UpdateEventGeneralQueryQueryVariables>;
export const UpdateEventMainImageQueryDocument = gql`
    query UpdateEventMainImageQuery($slug: String!) {
  event(slug: $slug) {
    ...UpdateEvent_event
    image {
      ...UpdateEventMainImage_image
    }
  }
  eventUpdates(slug: $slug) {
    ...UpdateEventLayout_eventUpdates
  }
}
    ${UpdateEvent_eventFragmentDoc}
${UpdateEventMainImage_imageFragmentDoc}
${UpdateEventLayout_eventUpdatesFragmentDoc}`;
export type UpdateEventMainImageQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpdateEventMainImageQueryQuery, UpdateEventMainImageQueryQueryVariables>, 'query'> & ({ variables: UpdateEventMainImageQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UpdateEventMainImageQueryComponent = (props: UpdateEventMainImageQueryComponentProps) => (
      <ApolloReactComponents.Query<UpdateEventMainImageQueryQuery, UpdateEventMainImageQueryQueryVariables> query={UpdateEventMainImageQueryDocument} {...props} />
    );
    
export type UpdateEventMainImageQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<UpdateEventMainImageQueryQuery, UpdateEventMainImageQueryQueryVariables> & TChildProps;
export function withUpdateEventMainImageQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEventMainImageQueryQuery,
  UpdateEventMainImageQueryQueryVariables,
  UpdateEventMainImageQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UpdateEventMainImageQueryQuery, UpdateEventMainImageQueryQueryVariables, UpdateEventMainImageQueryProps<TChildProps>>(UpdateEventMainImageQueryDocument, {
      alias: 'updateEventMainImageQuery',
      ...operationOptions
    });
};
export type UpdateEventMainImageQueryQueryResult = ApolloReactCommon.QueryResult<UpdateEventMainImageQueryQuery, UpdateEventMainImageQueryQueryVariables>;
export const UpdateEventSpeakersQueryDocument = gql`
    query UpdateEventSpeakersQuery($slug: String!) {
  event(slug: $slug) {
    ...UpdateEvent_event
    ...UpdateEventSpeakers_event
  }
  eventUpdates(slug: $slug) {
    ...UpdateEventLayout_eventUpdates
    ...UpdateEventSpeakers_eventUpdates
  }
}
    ${UpdateEvent_eventFragmentDoc}
${UpdateEventSpeakers_eventFragmentDoc}
${UpdateEventLayout_eventUpdatesFragmentDoc}
${UpdateEventSpeakers_eventUpdatesFragmentDoc}`;
export type UpdateEventSpeakersQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpdateEventSpeakersQueryQuery, UpdateEventSpeakersQueryQueryVariables>, 'query'> & ({ variables: UpdateEventSpeakersQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UpdateEventSpeakersQueryComponent = (props: UpdateEventSpeakersQueryComponentProps) => (
      <ApolloReactComponents.Query<UpdateEventSpeakersQueryQuery, UpdateEventSpeakersQueryQueryVariables> query={UpdateEventSpeakersQueryDocument} {...props} />
    );
    
export type UpdateEventSpeakersQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<UpdateEventSpeakersQueryQuery, UpdateEventSpeakersQueryQueryVariables> & TChildProps;
export function withUpdateEventSpeakersQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEventSpeakersQueryQuery,
  UpdateEventSpeakersQueryQueryVariables,
  UpdateEventSpeakersQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UpdateEventSpeakersQueryQuery, UpdateEventSpeakersQueryQueryVariables, UpdateEventSpeakersQueryProps<TChildProps>>(UpdateEventSpeakersQueryDocument, {
      alias: 'updateEventSpeakersQuery',
      ...operationOptions
    });
};
export type UpdateEventSpeakersQueryQueryResult = ApolloReactCommon.QueryResult<UpdateEventSpeakersQueryQuery, UpdateEventSpeakersQueryQueryVariables>;
export const PromoteEventQueryDocument = gql`
    query PromoteEventQuery($id: ID!) {
  node(id: $id) {
    id
    ... on Event {
      ...PromoteEvent_event
    }
  }
}
    ${PromoteEvent_eventFragmentDoc}`;
export type PromoteEventQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PromoteEventQueryQuery, PromoteEventQueryQueryVariables>, 'query'> & ({ variables: PromoteEventQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PromoteEventQueryComponent = (props: PromoteEventQueryComponentProps) => (
      <ApolloReactComponents.Query<PromoteEventQueryQuery, PromoteEventQueryQueryVariables> query={PromoteEventQueryDocument} {...props} />
    );
    
export type PromoteEventQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<PromoteEventQueryQuery, PromoteEventQueryQueryVariables> & TChildProps;
export function withPromoteEventQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PromoteEventQueryQuery,
  PromoteEventQueryQueryVariables,
  PromoteEventQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PromoteEventQueryQuery, PromoteEventQueryQueryVariables, PromoteEventQueryProps<TChildProps>>(PromoteEventQueryDocument, {
      alias: 'promoteEventQuery',
      ...operationOptions
    });
};
export type PromoteEventQueryQueryResult = ApolloReactCommon.QueryResult<PromoteEventQueryQuery, PromoteEventQueryQueryVariables>;
export const RemoteEventExtractionQueryDocument = gql`
    query RemoteEventExtractionQuery($id: ID!) {
  remoteEventExtraction(id: $id) {
    remoteEvent {
      ...ImportRemoteEvent_remoteEvent
    }
  }
}
    ${ImportRemoteEvent_remoteEventFragmentDoc}`;
export type RemoteEventExtractionQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RemoteEventExtractionQueryQuery, RemoteEventExtractionQueryQueryVariables>, 'query'> & ({ variables: RemoteEventExtractionQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RemoteEventExtractionQueryComponent = (props: RemoteEventExtractionQueryComponentProps) => (
      <ApolloReactComponents.Query<RemoteEventExtractionQueryQuery, RemoteEventExtractionQueryQueryVariables> query={RemoteEventExtractionQueryDocument} {...props} />
    );
    
export type RemoteEventExtractionQueryProps<TChildProps = {}> = ApolloReactHoc.DataProps<RemoteEventExtractionQueryQuery, RemoteEventExtractionQueryQueryVariables> & TChildProps;
export function withRemoteEventExtractionQuery<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoteEventExtractionQueryQuery,
  RemoteEventExtractionQueryQueryVariables,
  RemoteEventExtractionQueryProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, RemoteEventExtractionQueryQuery, RemoteEventExtractionQueryQueryVariables, RemoteEventExtractionQueryProps<TChildProps>>(RemoteEventExtractionQueryDocument, {
      alias: 'remoteEventExtractionQuery',
      ...operationOptions
    });
};
export type RemoteEventExtractionQueryQueryResult = ApolloReactCommon.QueryResult<RemoteEventExtractionQueryQuery, RemoteEventExtractionQueryQueryVariables>;